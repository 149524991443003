<script>
import Swal from 'sweetalert2'
import api from '../../../helpers/axios'

import '@sweetalert2/themes/bootstrap-4/bootstrap-4.scss'

export default {
  data() {
    return {
      files: [],
      totalSize: 0,
      errors: [],
      uploadPercentage: 0,
      isUploading: false,
      isQuickCheck: false,
      isProfilecheck: false,
      isFinished: false,
      hover: false,
      attributeData: this.attribute,
    }
  },
  props: {
    attribute: Object,
    subJobId: String,
  },
  methods: {
    async uploadSequence() {
      const uploaded = await this.uploadFile()

      if (!uploaded) {
        console.error('Uploading failed...')
        this.$emit('changed', true)
        return
      }

      // Run quickcheck
      const quick = await this.runQuickCheck()

      if (!quick) {
        console.log('Snelle analyze heeft fouten gevonden.')
        this.removeOnError()
        this.isQuickCheck = false
        this.$emit('changed', true)
        return
      }

      this.$emit('changed', true)

      // Run profile check&repair
      await this.profileCheck()

      this.$emit('changed', true)
      this.$emit('approved', true)
    },
    async uploadFile() {
      this.$toast.info('Uploaden gestart.', {
        position: 'top-right',
        duration: 4000,
        dismissible: true,
      })
      this.isUploading = true
      // Create formdata with files from upload component
      let formData = new FormData()
      // If there are multiple files, add them to the formData
      this.files.forEach(file => {
        formData.append('uploads', file)
      })

      // Send files to server
      const sent = await this.sendFiles(formData)

      this.isUploading = false

      if (!sent) {
        this.$toast.error('Uploaden niet gelukt.', {
          position: 'top-right',
          duration: 4000,
          dismissible: true,
        })

        return false
      }

      this.$toast.success('Uploaden voltooid.', {
        position: 'top-right',
        duration: 4000,
        dismissible: true,
      })

      // reset all file values
      this.removeFiles()

      return true
    },
    async sendFiles(formData) {
      // In order to show upload progress, this api call cannot be imported from a service
      const result = await api().post(`api/v1/uploads/${this.attribute.upload.id}/upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        onUploadProgress: function(progressEvent) {
          this.uploadPercentage = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total))
        },
      })

      if (result.status === 201) {
        return true
      }
      return false
    },
    async removeUploads() {
      Swal.fire({
        title: 'Bestand(en) verwijderen?',
        text: 'Dit kan niet ongedaan worden gemaakt!',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ja, verwijderen!',
        cancelButtonText: 'Annuleren',
      }).then(async result => {
        if (result.value) {
          const deleted = await api().delete(`api/v1/uploads/${this.attribute.upload.id}`)
          if (deleted.status === 204) {
            this.$toast.success('Bestand verwijderd.', {
              position: 'top-right',
              duration: 4000,
              dismissible: true,
            })
            this.isUploading = false
            this.removeFiles()
            this.$emit('changed', true)
            this.$emit('approved', true)
            return true
          }
        }

        return false
      })
    },
    async removeOnError() {
      const deleted = await api().delete(`api/v1/uploads/${this.attribute.upload.id}`)
      if (deleted.status === 204) {
        this.$toast.success('Bestand verwijderd.', {
          position: 'top-right',
          duration: 4000,
          dismissible: true,
        })
        this.isUploading = false
        this.removeFiles()
        this.$emit('approved', true)
        return true
      }
    },
    async runQuickCheck() {
      this.isQuickCheck = true
      this.$toast.info('PDF Analyse gestart.', {
        position: 'top-right',
        duration: 4000,
        dismissible: true,
      })

      const result = await api().post(`api/v1/pdfcheck/quickcheck/${this.subJobId}/${this.attribute.upload.id}`)

      this.isQuickCheck = false

      if (result.status !== 200) {
        this.$toast.error('PDF analyse niet gelukt.', {
          position: 'top-right',
          duration: 4000,
          dismissible: true,
        })
        return false
      }

      const analyzed = await this.analyzeQuickCheck(result.data.data, this.subJobId, this.attribute.upload.id)

      if (!analyzed) return false

      this.$toast.success('Analyse geslaagd.', {
        position: 'top-right',
        duration: 4000,
        dismissible: true,
      })

      return true
    },
    async analyzeQuickCheck(data, subJobId, uploadId) {
      let error = false
      // Loop subjobs
      for (let i = 0; i < data.subJob.length; i += 1) {
        const subjob = data.subJob[i]
        // Loop through attributes when subjob id is the correct one
        if (subjob.id === subJobId) {
          for (let j = 0; j < subjob.attributes.length; j += 1) {
            const attribute = subjob.attributes[j]
            this.attributeData = attribute
            // check quickcheck results on correct upload id
            if (attribute.upload.id === uploadId) {
              error = attribute.upload.check.find(obj => {
                if (obj.result === 'error') return obj
              })
              // If the error remains false, it meens there are no errors, so return true
              if (!error) return true

              if (error instanceof Object) {
                this.$toast.error('PDF analyse voltooid met fouten.', {
                  position: 'top-right',
                  duration: 4000,
                  dismissible: true,
                })
                return false
              }
            }
          }
        }
      }
    },
    async profileCheck() {
      this.$toast.info('PDF controle & reparatie gestart.', {
        position: 'top-right',
        duration: 4000,
        dismissible: true,
      })
      this.isProfilecheck = true

      const repaired = await api().post(`api/v1/pdfcheck/profilecheck/${this.subJobId}/${this.attribute.upload.id}`)

      if (repaired.data.status === 'error') {
        console.error(repaired.data.message)
        this.$toast.error(`Check & repair niet gelukt. ${repaired.data.message}`, {
          position: 'top-right',
          duration: 4000,
          dismissible: true,
        })
        return false
      }

      this.$toast.success('Check & repair afgerond.', {
        position: 'top-right',
        duration: 4000,
        dismissible: true,
      })

      this.isProfilecheck = false

      return true
    },
    removeFiles() {
      this.files = []
      this.totalSize = 0
      this.errors = []
      this.uploadPercentage = 0
    },
    removeFromErrorList(index) {
      this.errors.splice(index, 1)
    },
  },
  computed: {
    messages(file) {
      for (let i = 0; i < this.attribute.upload.files.length; i += 1) {
        const file = this.attribute.upload.files[i]
      }
    },
  },
  // created() {
  //   console.log(this.attribute.upload.id, this.subJobId)
  // },
  middleware: 'router-auth',
}
</script>

<template>
  <div>
    <transition name="fade">
      <form>
        <b-progress height="20px" :value="uploadPercentage" :max="100" variant="success" v-if="isUploading"></b-progress>

        <b-form-file
          style="color:green;"
          multiple
          accept="application/pdf"
          ref="fileInput"
          v-model="files"
          placeholder="Sleep hier uw bestand(en) of klik op 'Browse'"
          drop-placeholder="Laat hier los..."
          v-if="!isUploading && !isQuickCheck && !isFinished && !attribute.upload.files.length && !isProfilecheck"
        >
          <template slot="file-name" slot-scope="{ names }">
            <b-badge variant="dark">{{ names.join(' | ') }}</b-badge>
          </template>
        </b-form-file>

        <b-button v-if="attribute.upload.files.length && !isUploading && !isProfilecheck" variant="danger" @click="removeUploads" class="float-right">
          <i class="mdi mdi-delete mr-1"></i>Verwijder bestand(en)</b-button
        >

        <b-alert show variant="danger" class="mt-1" v-if="attribute.pages === 1 && files.length > 1"
          ><i class="mdi mdi-block-helper mr-2"></i> Fout: Uploaden meerdere bestanden niet mogelijk, 1 pagina gewenst</b-alert
        >

        <b-alert show variant="warning" class="mt-1" v-if="attribute.pages > 1 && files.length > 1"
          ><i class="mdi mdi-block-helper mr-2"></i> PDF bestanden worden samengevoegd tot 1 document.</b-alert
        >

        <b-button-group v-if="files.length && !attribute.upload.files.length && !isUploading" class="mt-1 float-right">
          <b-button
            id="upload-button"
            variant="success"
            @click="uploadSequence()"
            v-if="(attribute.pages === 1 && files.length === 1) || (attribute.pages > 1 && files.length > 0)"
            ><i class="mdi mdi-upload"></i> Upload</b-button
          >
          <b-tooltip target="upload-button" placement="bottom">Bestand(en) uploaden</b-tooltip>
          <b-button id="remove-button" variant="danger" @click="removeFiles()"><i class="mdi mdi-close-circle-outline"></i></b-button>
          <b-tooltip target="remove-button" placement="bottom">Haal bestand(en) weg</b-tooltip>
        </b-button-group>

        <div v-if="errors.length">
          <b-alert show variant="danger" class="mt-1 pa-1" v-for="(error, index) in errors" :key="index">
            <i class="mdi mdi-block-helper mr-2"></i>{{ error }}
            <span class="remove-error float-right" @click="removeFromErrorList(index)"><i class="mdi mdi-close-circle-outline"></i></span>
          </b-alert>
        </div>

        <div v-if="isQuickCheck" style="display: inline-block; vertical-align: middle; padding:10px 0;">
          <img src="@/assets/images/pdf-quickcheck.gif" alt="quickcheck" height="50" />
          <span class="ml-2 font-size-14"><strong>Snelle PDF analyze</strong></span>
        </div>

        <div v-if="isProfilecheck">
          <img src="@/assets/images/pdf-check.gif" alt="pdfcheck" height="50" />
          <span class="ml-2 font-size-14"><strong>PDF Check & Repair (let op: dit kan enige tijd duren.)</strong></span>
        </div>
      </form>
    </transition>
  </div>
</template>

<style>
.popup-gallery {
  padding: 20px;
  background-color: rgb(224, 224, 224);
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.shake {
  animation: shake 0.82s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
}
@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
