<script>
import Upload from './upload-component'
import VueEasyLightbox from 'vue-easy-lightbox'
import api from '../../../helpers/axios'

const url = process.env.NODE_ENV === 'production' ? process.env.VUE_APP_API_PROD_URL : process.env.VUE_APP_API_DEV_URL

export default {
  components: {
    Upload,
    VueEasyLightbox,
  },
  data() {
    return {
      subJob: this.subjob,
      attributeData: this.attribute,
      showbleed: false,
      previews: [],
      visible: false,
      singleImage: false,
      visibleCaption: false,
      index: 0, // default: 0
      backendUrl: url,
    }
  },
  props: {
    attribute: Object,
    subjob: Object,
  },
  methods: {
    async getAttribute() {
      this.previews = []
      // Get the subjob
      const result = await api().get(`api/v1/jobs/${this.attribute.upload.job}/subjob/${this.subJob.id}`)
      const subjob = result.data.data

      for (let i = 0; i < subjob.attributes.length; i += 1) {
        const attribute = subjob.attributes[i]

        if (attribute._id === this.attribute._id) {
          this.attributeData = attribute
        }
      }
      this.getPreviews()
    },
    setApproved() {
      this.$emit('approved', true)
    },
    showImg(index) {
      this.index = index
      this.visible = true
    },
    handleHide() {
      this.visible = false
    },
    showCaptiomImg(index) {
      this.index = index
      this.visibleCaption = true
    },
    handleCaptionHide() {
      this.visibleCaption = false
    },
    getPreviews() {
      const images = []
      if (this.attributeData.upload.checkResult.length) {
        let j = 1
        let k = 1

        for (let i = 0; i < this.attributeData.upload.checkResult.length; i += 1) {
          const result = this.attributeData.upload.checkResult[i]

          if (this.showbleed === false && result.resultType === 'preview') {
            images.push({
              src: `${this.backendUrl}/${this.attributeData.upload.id}/${result.filename}?${Date.now()}`,
              title: `Pagina ${j} zonder bleed.`,
              type: 'preview',
            })
            j++
          }

          if (this.showbleed === true && result.resultType === 'bleedpreview') {
            if (result.resultType === 'bleedpreview') {
              images.push({
                src: `${this.backendUrl}/${this.attributeData.upload.id}/${result.filename}?${Date.now()}`,
                title: `Pagina ${k} met bleed.`,
                type: 'bleedpreview',
              })
              k++
            }
          }
        }
      }
      this.previews = images
    },
  },
  created: function() {
    this.getAttribute()
    this.getPreviews()
    // console.log('subjob', this.subjob.id)
    // console.log('upload', this.attribute.upload.id)
  },
  watch: {
    showbleed() {
      this.previews = []
      this.getPreviews()
    },
  },
}
</script>

<template>
  <tbody>
    <tr :key="attributeData.id">
      <td style="width:35%">
        <table class="table table-description" style="width:100%;">
          <tr>
            <td style="width:20%;"><strong>Materiaal</strong></td>
            <td>{{ subJob.paper }}</td>
          </tr>
          <tr>
            <td><strong>Bedrukking</strong></td>
            <td>{{ subJob.colors }}</td>
          </tr>
          <tr>
            <td><strong>Aantal</strong></td>
            <td>{{ subJob.copies }} stuks</td>
          </tr>
          <tr>
            <td><strong>Afmeting</strong></td>
            <td>{{ attribute.width_net }} x {{ attribute.height_net }} mm</td>
          </tr>
        </table>
      </td>
      <td>
        <strong>{{ attributeData.pages }} pagina's</strong>
      </td>
      <td>
        <strong>{{ attributeData.description }}</strong>
      </td>
      <td style="width:40%;">
        <Upload :attribute="attributeData" @changed="getAttribute" @approved="setApproved" :subJobId="subJob.id" />
      </td>
    </tr>
    <tr :key="attributeData.id + 1" v-if="attributeData.upload.check.length">
      <td colspan="4">
        <div class="row">
          <div v-for="check in attributeData.upload.check" :key="check._id" class="col-6">
            <b-alert variant="warning" show v-if="check.result === 'warning'">{{ check.message }}</b-alert>
            <b-alert variant="danger" show v-if="check.result === 'error'">Pagina {{ check.pageNumber }}: {{ check.message }}</b-alert>
          </div>
        </div>
      </td>
    </tr>

    <tr v-if="attributeData.upload.checkResult.length" :key="attributeData.id + 2">
      <td colspan="4">
        <strong>Previews</strong>
        <p style="padding:10px 0;">
          <b-form-checkbox switch size="md" v-model="showbleed"><mark>Toon preview(s) met afloop</mark></b-form-checkbox>
          <br /><small>Klik op afbeelding voor grote weergave.</small>
        </p>

        <div class="row" v-if="previews.length">
          <div class="popup-gallery">
            <div v-for="(src, index) in previews" :key="index" class="float-left" @click="() => showImg(index)">
              <img :src="src.src" width="150" class="mr-2 mb-2 thumbnail" loading="lazy" />
            </div>
            <vue-easy-lightbox :visible="visible" :imgs="previews" :index="index" @hide="handleHide"></vue-easy-lightbox>
          </div>
        </div>
      </td>
    </tr>

    <tr v-if="attributeData.upload.reportResult.length" style="background-color:#fff;">
      <td colspan="4">
        <div class="row">
          <div v-for="result in attributeData.upload.reportResult" :key="result._id" class="mr-1">
            <div v-if="result.fixupText">
              <b-alert show variant="success" v-if="result.severity === 'SUCCESS'" class="font-size-11">
                <i class="mdi mdi-check-all mr-2"></i>{{ result.fixupText }} Succesvol!</b-alert
              >
              <b-alert show variant="danger" v-if="result.severity === 'ERROR'" class="font-size-11">
                <i class="mdi mdi-block-helper mr-2"></i>{{ result.fixupText }} Niet gelukt, maar niet ernstig.</b-alert
              >
              <b-alert show dismissible variant="warning" v-if="result.severity === 'WARNING'" class="font-size-11">
                <i class="mdi mdi-alert-outline mr-2"></i>{{ result.fixupText }} Niet gelukt, onherstelbare fout. Repareer en upload opnieuw!
              </b-alert>
            </div>
          </div>
        </div>
      </td>
    </tr>
  </tbody>
</template>

<style>
.thumbnail:hover {
  cursor: pointer;
}
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.table-description {
  padding: 5px;
}
.table-description td {
  padding: 5px;
}
</style>
