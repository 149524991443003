import axios from 'axios'

const baseURL = process.env.NODE_ENV === 'development' ? process.env.VUE_APP_API_DEV_URL : process.env.VUE_APP_API_PROD_URL

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: false,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  },
})

// // Add a response interceptor
// axios.interceptors.response.use(
//   function(response) {
//     // Do something with response data
//     return response
//   },
//   function(error) {
//     // Do something with response error
//     return Promise.reject(error)
//   },
// )

export default () => {
  return instance
}
