<script>
import Swal from 'sweetalert2'
import api from '../../../helpers/axios'

export default {
  data() {
    return {
      deliveryAddress: this.subjob.deliveryAddress,
      totalCopies: 0,
    }
  },
  props: {
    subjob: Object,
    jobId: String,
  },
  methods: {
    /**
     * Push the row in form
     */
    addAddress() {
      this.deliveryAddress.push({ company: '', contact: '', address: '', zipcode: '', city: '', country: '', countryCode: '', quantity: 0 })
      this.$emit('changed', true)
    },
    /**
     * Delete the row
     */
    deleteRow(index) {
      Swal.fire({
        title: 'Afleveradres verwijderen?',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ja, verwijderen!',
        cancelButtonText: 'Nee',
      }).then(result => {
        if (result.value) {
          this.deliveryAddress.splice(index, 1)
          this.calcCopies()
          this.$emit('changed', true)
        }
      })
    },
    saveAddresses() {
      Swal.fire({
        title: 'Afleveradres(sen) opslaan?',
        text: 'U heeft geen mogelijkheid om te herstellen.',
        type: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ja, opslaan!',
        cancelButtonText: 'Annuleren',
      }).then(async result => {
        if (result.value) {
          const updated = await api().patch(`api/v1/jobs/${this.jobId}/delivery/${this.subjob.id}`, this.deliveryAddress)
          if (updated.status === 200) {
            this.$toast.success('Afleveradres(sen) opgeslagen.', {
              position: 'top-right',
              duration: 4000,
              dismissible: true,
            })

            this.$emit('changed', true)
            return true
          }
        }

        return false
      })
    },
    calcCopies() {
      this.totalCopies = 0
      for (let i = 0; i < this.deliveryAddress.length; i += 1) {
        const address = this.deliveryAddress[i]
        this.totalCopies = this.totalCopies + parseInt(address.quantity)
      }
    },
    async resetAddresses() {
      try {
        this.previews = []
        this.totalCopies = 0
        const result = await api().get(`api/v1/jobs/${this.jobId}/subjob/${this.subjob.id}`)
        this.deliveryAddress = result.data.data.deliveryAddress
        this.calcCopies()
        this.$emit('changed', true)
      } catch (error) {
        console.error(error)
      }
    },
  },
  computed: {
    isValidQuantity() {
      if (this.deliveryAddress.length > 1) {
        for (let i = 0; i < this.deliveryAddress.length; i += 1) {
          const address = this.deliveryAddress[i]
          if (address.quantity === 0) return 'is-invalid'
        }
      }

      if (this.totalCopies !== this.subjob.copies) {
        return 'is-invalid'
      }

      return 'is-valid'
    },
  },
  created: function() {
    this.calcCopies()
  },
  middleware: 'router-auth',
}
</script>

<template>
  <div>
    <div v-if="deliveryAddress.length">
      <form class="repeater" @submit.prevent="saveAddresses">
        <span v-for="(address, index) in deliveryAddress" :key="index + 1 + '-1'">
          <div>
            <strong class="font-size-14">Adres {{ index + 1 }}</strong>
          </div>
          <div class="row">
            <div class="form-group col-md-2">
              <label :for="'organisatie-' + index" class="font-size-12">Organisatie</label>
              <input
                :id="'organisatie -' + index"
                v-model="deliveryAddress[index].company"
                type="text"
                :name="address.company"
                class="form-control font-size-11"
                required
              />
              <label :for="'contact -' + index" class="font-size-12">Contact</label>
              <input
                :id="'contact -' + index"
                v-model="deliveryAddress[index].contact"
                type="text"
                :name="address.contact"
                class="form-control font-size-11"
                required
              />
            </div>

            <div class="form-group col-md-2">
              <label :for="'address -' + index" class="font-size-12">Adres</label>
              <input
                :id="'address -' + index"
                v-model="deliveryAddress[index].address"
                type="text"
                :name="address.address"
                class="form-control font-size-11"
                required
              />
            </div>
            <div class="form-group col-md-2">
              <label :for="'zipcode -' + index" class="font-size-12">Postcode</label>
              <input :id="'zipcode -' + index" v-model="deliveryAddress[index].zipcode" type="text" :name="address.zipcode" class="form-control font-size-11" />
              <label :for="'city -' + index" class="font-size-12">Plaats</label>
              <input :id="'city -' + index" v-model="deliveryAddress[index].city" type="text" :name="address.city" class="form-control font-size-11" required />
            </div>

            <div class="form-group col-md-2">
              <label :for="'country -' + index" class="font-size-12">Land</label>
              <input
                :id="'country -' + index"
                v-model="deliveryAddress[index].country"
                type="text"
                :name="address.country"
                class="form-control font-size-11"
                style="text-transform:uppercase;"
                required
              />
              <label :for="'countryCode -' + index" class="font-size-12">Landcode</label>
              <input
                :id="'countryCode -' + index"
                v-model="deliveryAddress[index].countryCode"
                type="text"
                :name="address.countryCode"
                class="form-control font-size-11"
                style="text-transform:uppercase;"
                required
              />
            </div>
            <div class="form-group col-md-2">
              <label :for="'quantity -' + index">Aantal</label>
              <input
                :id="'quantity -' + index"
                v-if="address.quantity > 0"
                v-model="deliveryAddress[index].quantity"
                type="number"
                :name="address.quantity"
                class="form-control font-size-11 mb-1"
                :class="isValidQuantity"
                @change="calcCopies"
                required
              />
              <input
                :id="quantity + '-' + index"
                v-else
                type="number"
                :name="address.quantity"
                v-model="deliveryAddress[index].quantity"
                class="form-control font-size-11 mb-1"
                :class="isValidQuantity"
                @change="calcCopies"
                required
              />

              <span v-if="isValidQuantity === 'is-invalid'" class="invalid-tooltip" style="position:relative; margin-top:-115px;"
                >Totaal aantal klopt niet of waarde 0 niet toegestaan.</span
              >
            </div>
            <div class="col-md-2 align-self-center">
              <input type="button" class="btn btn-danger" value="Verwijder" @click="deleteRow(index)" v-if="deliveryAddress.length > 1" />
            </div>
          </div>

          <hr />
        </span>
        <b-button-group class="float-right">
          <b-button variant="success" @click="addAddress">Voeg adres toe</b-button>
          <b-button variant="primary" @click="resetAddresses">Herstel adres(sen)</b-button>
          <b-button type="submit" variant="success" v-if="isValidQuantity === 'is-valid'"
            ><i
              class="mdi mdi-content-save-outline
"
            ></i>
            Opslaan</b-button
          >
        </b-button-group>
      </form>
    </div>
    <div v-if="!deliveryAddress.length">
      <b-alert show variant="danger" class="font-size-11"> <i class="mdi mdi-check-all mr-2"></i>Voeg minimaal één een afleveradres toe.</b-alert>
      <b-button-group class="float-right">
        <b-button variant="success" @click="addAddress">Voeg adres toe</b-button>
        <b-button variant="primary" @click="resetAddresses">Herstel adres(sen)</b-button>
        <b-button type="submit" variant="success" v-if="isValidQuantity === 'is-valid'"
          ><i
            class="mdi mdi-content-save-outline
"
          ></i>
          Opslaan</b-button
        >
      </b-button-group>
    </div>
  </div>
</template>
